<template>
  <p>
    信息无障碍是指无论健全人还是残疾人、无论年轻人还是老年人都能够从信息技术中获益，任何人在任何情况下都能平等地、方便地、无障碍地获取信息、利用信息。
  </p>
  <p>更多内容正在建设中，尽请期待！</p>
  <p>
    <a href="https://beian.miit.gov.cn/" target="_blank"
      >粤ICP备2021058479号-2</a
    >
  </p>
</template>
